import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//修改路由报错
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/Login.vue")//登录页面
  },
  {
    path: '/',
    name: 'framework',
    component: () => import("@/views/Framework.vue"),//首页
    children: [
      {
        path: '/more',
        component: () => import("@/views/main/More.vue")//主页面
      },
      // {
      //   path: '/allFile',
      //   component: () => import("@/views/main/More.vue")//所有文件
      // },
      {
        path: '/picture',
        component: () => import("@/views/main/Picture.vue")//所有图片
      },
      {
        path: '/document',
        component: () => import("@/views/main/Document.vue")//所有文档
      },
      {
        path: '/audio',
        component: () => import("@/views/main/Audio.vue")//所有音频
      },
      {
        path: '/video',
        component: () => import("@/views/main/Video.vue")//所有视频
      },
      {
        path: '/application',
        component: () => import("@/views/main/Application.vue")//所有应用
      },
      {
        path: '/share',
        component: () => import("@/views/share/Share.vue")//分享页面
      },
      {
        path: '/receivedShare',
        component: () => import("@/views/share/ReceivedShare.vue")//分享页面
      },
      {
        path: '/recycle',
        component: () => import("@/views/recycle/Recycle.vue")//回收
      },
      {
        path: '/users',
        component: () => import("@/views/user/User.vue")//管理员管理
      },
      {
        path: '/feedback',
        component: () => import("@/views/feedback/FeedBack.vue")//用户反馈
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
