// 约定通用键名
const INFO_KEY= 'cunleme_token'
const INFO_USER= 'cunleme_userInfo'

// 获取token信息
export const getToken = () => {
  const defaultObj = {token: ''}
  const result = localStorage.getItem(INFO_KEY)
  return result ? JSON.parse(result) : defaultObj
}

// 设置个人信息
export const setToken = (obj) => {
  localStorage.setItem(INFO_KEY, JSON.stringify(obj))
}

// 移除个人信息
export const removeToken = () => {
  localStorage.removeItem(INFO_KEY)
}

// 获取token信息
export const getLocalUserInfo = () => {
  const defaultObj = {}
  const result = localStorage.getItem(INFO_USER)
  return result ? JSON.parse(result) : defaultObj
}

// 设置个人信息
export const setLocalUserInfo = (obj) => {
  localStorage.setItem(INFO_USER, JSON.stringify(obj))
}

// 移除个人信息
export const removeLocalUserInfo = () => {
  localStorage.removeItem(INFO_USER)
}
