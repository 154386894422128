import Vue from 'vue'
import Vuex from 'vuex'

import user from "./modules/user"

Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    userPercentage:0
  },
  getters: {
    token(state){
      //console.log("进入获取token")
      //console.log("token:"+JSON.stringify(state.user.userInfo))
      if(!state.user.token) return
      return state.user.token
    },
    userInfo(state){
      return state.user.userInfo
    }
  },
  mutations: {
  },
  actions: {},
  modules: {
    user,
  }
})