import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import store from '@/store'
import ElementUI from 'element-ui'
import axios from 'axios';
import "./less/reset.css"
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(router)
Vue.use(store)

new Vue({
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  router,
  store,
}).$mount('#app')

