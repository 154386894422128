<template>
  <div>hello，今天你存了么</div>
</template>

<script>

export default {
  data() {
    return{
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
