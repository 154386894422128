import { getToken, setToken, removeToken,
   getLocalUserInfo, setLocalUserInfo, removeLocalUserInfo } from "@/utils/storage"

export default{
  namespaced: true,
  state(){
    return{
      //getToken()
      token: getToken(),
      userInfo: getLocalUserInfo(),
      // userInfo: {
      //   token: '123'
      // }
    }
  },//数据
  mutations:{
    setToken(state, obj){
      //console.log("设置userInfo:"+JSON.stringify(obj))
      if(!obj){
        console.log("无法设置token,值为空")
        return
      }
      setToken(obj)
      state.token = getToken()
    },
    //用户基本信息
    setUserInfo(state, obj){
      if(!obj){
        console.log("无法设置userInfo,值为空")
        return
      }
      setLocalUserInfo(obj)
      state.userInfo = getLocalUserInfo()
    },
    //移除所有信息
    removeAllInfo(){
      removeToken();
      removeLocalUserInfo();
    }
  },//修改数据方法
  actions:{

  }, // 提供异步操作
  getters:{
    
  }//获取数据
}